export const environment = {
    production: true,
    region: 'ap-southeast-2',
    endpoint: 'https://api.reali.au',
		wsEndPoint: 'wss://api.reali.au',
    logLevel: 'debug',
    buildId: process.env.NG_APP_BUILD_ID || 'local',
    defaultCollectionLimit:10,
    enableSentry: true,
    defaultTestEmailAddress: "",
version: "prod_764",
		baseDomain: 'reali.au',
};
