import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnleashedCustomer } from 'src/app/main/model/unleashed.model';
import { CustomerService } from 'src/app/main/services/customer.service';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { UserListComponent } from 'src/app/main/users/customer-users/user-list/user-list.component';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: []
})
export class CustomerEditComponent {
  public customerGuid: string;
  public customer: UnleashedCustomer;
  public activeTabIdx: number = 0;
  @ViewChild(UserListComponent, { static: false })
  private userListComponent!: UserListComponent;
  constructor(
    public route: ActivatedRoute,
    private customerService: CustomerService,
    public router: Router,
    private customerUserService: CustomerUserService
  ) { }

  getTabs(): string[] {
    return ['Overview', 'Users', 'Guests', 'Collections', 'Addresses', 'Groups', 'Business units', 'Products', 'Domain', 'Document', 'Decorations'];
  }

  ngOnInit() {
    const activeTab = this.route.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
    }
    this.route.params.subscribe(params => {
      this.customerGuid = params['customerId'];
      let activeTab = params['activeTab'];

      if (this.customerGuid) {
        this.customerService.getById(this.customerGuid)
          .subscribe(customer => {
            this.customer = Object.assign(new UnleashedCustomer(), customer);
          });
      }
    });
  }

  getIsGuest(): boolean {
    return this.activeTabIdx === 2;
  }

  onTabChange(event: number): void {
    this.activeTabIdx = event;
  }

  addRoutes() {
    if (this.activeTabIdx == 4) {
      this.router.navigate(['/manage/customer/edit', this.customer.id, 'address']);
    } else if (this.activeTabIdx == 6) {
      this.router.navigate(['/manage/customer/edit', this.customer.id, 'business-unit']);
    } else if (this.activeTabIdx == 9) {
      this.router.navigate(['/manage/customer/edit', this.customer.id, 'document']);
    } else if (this.activeTabIdx == 1) {
      this.router.navigate(['manage/users/edit', this.customer.id, 'user'], { queryParams: { tab: 'user', isGuest: false } });
    } else if (this.activeTabIdx == 2) {
      this.router.navigate(['manage/users/edit', this.customer.id, 'user'], { queryParams: { tab: 'guest', isGuest: true } });
    } else if (this.activeTabIdx == 5) {
      this.router.navigate(['manage/customer/groups', this.customer.id], { queryParams: { isCustomer: true } });
    } else if (this.activeTabIdx == 3) {
      this.router.navigate(['/manage/collections/edit'], { queryParams: { customerId: this.customer.id } });
    }
  }

  downloadImportTemplate() {
    let csvColumns = [
      [
        'First Name',
        'Last Name',
        'Email',
        'Employee Number',
        'Cost Centre',
        'Business Unit',
        'Role (Purchasing Manager/Purchaser/Employee/View Only)',
        'Purchasing Approval Manager Email (If Applicable)'
      ]
    ];

    if (this.activeTabIdx == 2) {
      csvColumns[0] = csvColumns[0].filter(column =>
        column !== 'First Name' &&
        column !== 'Last Name' &&
        column !== 'Employee Number' &&
        column !== 'Cost Centre'
      );
    }

    const csvString = csvColumns.map(row => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "import_template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  async fileUploadChange(evt: Event) {
    const element = evt.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (!fileList) {
      return;
    }

    const file = fileList[0];

    if (file.type !== "text/csv") {
      alert("Please select a user Import CSV")
      return;
    }

    // @ts-ignore
    const fileText = await file.text();

    if (fileText.length === 0) {
      alert("Please select a Valid user Import CSV")
      return;
    }

    let data = {
      customerId: this.customerGuid,
      fileContent: fileText,
      isGuest: false
    }

    if (this.activeTabIdx == 2) {
      data.isGuest = true;
    }

    this.customerUserService.importUser(data).subscribe({
      next: resp => {
        const messages: string[] = [];
        if (resp.results && resp.results.length) {
          messages.push(`Created ${resp.results.length} users`);
        } else {
          messages.push(`Created 0 users`);
        }
        if (resp.messages && resp.messages.length > 0) {
          messages.push('Warning: Not all users could be imported');
          messages.push(...resp.messages);
        }
        alert(messages.join("\n"));
        this.userListComponent.loadCustomerUsers();
      },
      error: err => {
        console.error(err);
      },
      complete: () => {
        element.value = '';
      }
    });
  }

  showAddButton(): boolean {
    return this.activeTabIdx === 1 || this.activeTabIdx === 2 ||
      this.activeTabIdx === 4 || this.activeTabIdx === 6 ||
      this.activeTabIdx === 9 || this.activeTabIdx == 5 || this.activeTabIdx == 3;
  }

  getAddButtonLabel(): string {
    switch (this.activeTabIdx) {
      case 1: return 'user';
      case 2: return 'guest';
      case 3: return 'collection';
      case 4: return 'address';
      case 5: return 'group';
      case 6: return 'Business Unit';
      case 9: return 'document';
      default: return '';
    }
  }
}
